<template>
  <v-list>
    <v-list-item v-for="(pin, index) in pinList"
                 v-bind:key="index"
                 inactive
                 :ripple="false">
      <v-list-item-icon>
        <v-icon class="material-icons-outlined">password</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-size-03">
          <span v-if="pin.hidePassword">******</span>
          <span v-else>{{  pin.lockPassword }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>{{ pin.name }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-progress-circular
            v-if="index === deletionIndex"
            indeterminate
            color="primary"
        ></v-progress-circular>
        <v-btn v-else
               icon
               @click="deletePin(pin.lockUser)">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn icon
               v-if="pin.hidePassword"
               @click="pin.hidePassword = !pin.hidePassword">
          <v-icon>visibility_off</v-icon>
        </v-btn>
        <v-btn icon
               v-else
               @click="pin.hidePassword = !pin.hidePassword">
          <v-icon>visibility</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="pinList.length > 0"
                 inactive
                 :ripple="false">
      <v-list-item-icon>
        <v-chip> {{pinList.length}}/{{permanent ? '5' : '4'}}</v-chip>
      </v-list-item-icon>
      <v-list-item-content></v-list-item-content>
      <!-- Feature on hold
      <v-list-item-action>
        <v-btn v-if="!syncLoading"
               icon
               @click="syncPins">
          <v-icon>sync</v-icon>
        </v-btn>
        <v-progress-circular
            v-else
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-list-item-action>-->
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "KaadasLockPinList",
  props: {
    pinList: {
      default: []
    },
    deletionIndex: {
      default: null,
    },
    permanent: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      hidePassword: true,
      syncLoading: false
    }
  },
  methods: {
    deletePin(lockUser) {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('kaadas-lock.pins-expansion-panel.remove-pin.confirm'),
        confirmLabel: this.$t('kaadas-lock.pins-expansion-panel.remove-user-button')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$emit('deleteUserPin', lockUser)
        this.$root.$off('dialogConfirmed')
      })
    }
    /**
     * Feature on hold
    syncPins() {
      this.syncLoading = true
      this.$rhRequest.sendGet({
        endpoint: "kaadas/sync-occupied-users",
        timeout: "600000",
        params: {
          deviceId: 36,
          permanent: this.permanent
        }
      }, () => {
        this.syncLoading = false
        this.$emit("reloadPinList")
      }, (e) => {
        alert('syncPinsError')
        this.syncLoading = false
        console.error(e)
      })

    }*/
  },
  computed: {}
}
</script>
