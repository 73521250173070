<template>
  <v-expansion-panels ref="kaadasLockPinTile">
    <v-expansion-panel class="tileBackground">
      <v-expansion-panel-header class="px-5">
        <div>
          <v-icon class="material-icons-outlined non-flip"
                  color="primary">
            {{ icon }}
          </v-icon>
          <span v-if="permanent" class="font-weight-bold font-size-04 mr-4 primary--text">
            {{ $t('kaadas-lock.pins-expansion-panel.permanent-title') }}
          </span>
          <span v-else class="font-weight-bold font-size-04 mr-4 primary--text">
            {{ $t('kaadas-lock.pins-expansion-panel.temporary-title') }}
          </span>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-skeleton-loader v-if="loading"
                           type="article"
                           class="mb-3"
                           height="55"/>
        <kaadas-lock-pin-list v-else
                              @reloadPinList="getPins"
                              @deleteUserPin="deletePin"
                              :device="device"
                              :pinList="pinList"
                              :permanent="permanent"
                              :deletionIndex="deletionIndex"
        />
        <v-btn depressed block large
               class="font-weight-bold"
               color="primary"
               :disabled="btnDisabled"
               @click="addPin"
               v-html="$t('kaadas-lock.pins-expansion-panel.add-pin')"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import KaadasLockPinList from "@/templates/components/devices/access/KaadasLockPinList.vue";
import config from '@/config/config.app.json'

export default {
  name: "KaadasLockPinTile",
  components: {KaadasLockPinList},
  props: {
    device: Object,
    permanent: {
      default: true,
      type: Boolean
    },
  },
  data: function () {
    return {
      loading: false,
      deletionIndex: null,
      pinList: []
    }
  },
  methods: {
    init() {
      this.getPins()
      setInterval(() => {
        this.getPins()
      }, config.updateInterval)
    },
    addPin() {
      this.$root.bisadialog.toggle('addKaadasPin', true, {device: this.device, isPermanent: this.permanent})
    },
    getPins() {
      if (this.permanent) {
        this.getPermanentPins()
      } else {
        this.getTemporaryPins()
      }
    },
    getPermanentPins() {
      this.loading = true
      this.pinList = []

      this.$rhRequest.sendGet({
        endpoint: "kaadas/get-permanent-pins",
        params: {
          deviceId: this.device.id
        }
      }, (resp) => {
        resp.data.data.forEach((pin) => {
          pin.hidePassword = true
          this.pinList.push(pin)
        })
        this.loading = false
      }, (e) => {
        console.error(e)
      })
    },
    getTemporaryPins() {
      this.loading = true
      this.pinList = []

      this.$rhRequest.sendGet({
        endpoint: "kaadas/get-temporary-pins",
        params: {
          deviceId: this.device.id
        }
      }, (resp) => {
        resp.data.data.forEach((pin) => {
          pin.hidePassword = true
          this.pinList.push(pin)
        })
        this.loading = false
      }, (e) => {
        console.error(e)
      })
    },
    deletePin: function (lockUser) {
      this.pinList.find((obj, index) => {
        if (obj.lockUser === lockUser) {
          this.deletionIndex = index
          return
        }
      })
      this.$rhRequest.sendDelete({
        endpoint: 'kaadas/delete-user-pin',
        params: {
          'deviceId': this.device.id,
          'lockUser': lockUser
        }
      }, () => {
        this.getPins()
        this.$root.bisatoast.success({
          message: this.$t('kaadas.delete.success'),
          showCloseBtn: true
        })
        this.deletionIndex = null
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.deletionIndex = null
      })
    }
  },
  computed: {
    icon() {
      return this.permanent ? 'enhanced_encryption' : 'lock_clock'
    },
    btnDisabled () {
      let result = false
      let maxLength = this.permanent ? 4 : 3

      if (this.pinList.length > maxLength || this.loading === true) {
        result = true
      }

      return result
    }
  },
  mounted() {
    let emitTo = this.permanent ? 'Permanent' : 'Temp'
    emitTo = 'loadKaadasUser' + emitTo  + this.device.id
    this.init()
    this.$root.$on(emitTo, () => {
      this.getPins()
    })
  }
}
</script>
